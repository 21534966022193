import MainAvatar from '@components/profile/MainAvatar';
import { useAuthState } from '@providers/AuthProvider';
import { User } from '@src/API';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import Link from 'next/link';
import IconArrowLeftSvg from 'public/ic-arrow-left.svg';

export default function InboxAvatarView({ isChatWidget = false }: { isChatWidget?: boolean }) {
  const { userChannels, selectedUserChannelId, clearSelectedUserChannel } =
    useChatState() as ChatContextValue;

  const { user } = useAuthState();

  const selectedUserChannel = userChannels.find((userChannel) => {
    return userChannel.id === selectedUserChannelId;
  });
  const userChannelsOfChannel = selectedUserChannel?.channel?.user_channels?.items || [];
  const allUsers = userChannelsOfChannel.map((item) => item?.user) as User[];
  const receiver = allUsers.find((item) => item?.id !== user?.id);

  const handleBackToSelectChannel = () => {
    clearSelectedUserChannel();
  };

  if (!selectedUserChannelId) return <div></div>;

  return (
    <div className="items-center flex">
      <button className="flex md:hidden items-center p-2 mr-2" onClick={handleBackToSelectChannel}>
        <IconArrowLeftSvg />
      </button>
      <Link href={`/users/${receiver?.username || ''}`} passHref>
        <a href="replace" className="flex items-center">
          <MainAvatar
            sizeType={isChatWidget ? 'xs' : 'sm'}
            imageSrc={receiver?.profile_picture_url || ''}
            linkUrl={`/users/${receiver?.username || ''}`}
            displayName={receiver?.display_name || 'Người dùng'}
            canOpenProfile={false}
            isOpenNewTab={false}
          />

          <div className="flex flex-col ml-2">
            <span className="text-m text-content-t100 font-semibold hover:underline">
              {receiver?.display_name || 'Người dùng'}
            </span>
          </div>
        </a>
      </Link>
    </div>
  );
}
