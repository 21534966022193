function DrDivider({
  direction = 'horizontal',
  margin = '0',
  height = 'h-px',
  bgColor = 'bg-greyscale-g3',
}: {
  direction?: 'horizontal' | 'vertical';
  margin?: string;
  height?: string;
  bgColor?: string;
}) {
  let style;
  if (direction === 'horizontal') {
    style = `w-full ${height} ${bgColor} my-${margin}`;
  } else {
    style = `h-full w-px ${bgColor} mx-[${margin}]`;
  }
  return <div className={style} />;
}

export default DrDivider;
