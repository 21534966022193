import DrDivider from '@components/DrDivider';
import MessageItemView from '@components/inbox/MessageItemView';
import { User } from '@src/API';
import InboxAvatarView from '@src/components/inbox/InboxAvatarView';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import MessageInputView from './MessageInputView';

const CircularProgress = dynamic(() => import('@mui/material/CircularProgress'));

const TOP_TRIGGER_FETCHING_THRESHOLD = 160;
const OFFSET_DECREASE_CONSTANT = 64;
enum MessageFetchingType {
  INITIAL,
  NEXT,
}
/**
 * For this component, we use refs to achieve the following scrolling UX:
 *
 * - After the first page is fetched, the scrollbar should start at the bottom
 * - When user scrolls top and triggers next page fetching, after the next page is fetched,
 *  the scrollbar should be adjusted so that the last message of the previous page is roughly at the same position
 * - When user scrolls to the bottom, and new messages are added to the bottom, the scrollbar should stay at the bottom
 * - When user is not at the bottom, and they sends a message, the scrollbar should go to the bottom
 * - When user is not at the bottom, and they receives a message, the scrollbar should stay at the same position
 *
 * TODO (long): Add back the scroll to bottom button
 *
 */
export default function MessagesView({ isChatWidget = false }: { isChatWidget?: boolean }) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  // This is for scrolling to the bottom after first page is fetched
  const fetchingTypeRef = useRef<MessageFetchingType>();
  // Use this anchor to adjust scrollbar to the bottom
  const bottomAnchorRef = useRef<HTMLDivElement>(null);
  // Use this to adjust scrollbar after next pages are added to the top
  const prevScrollHeight = useRef(0);

  const {
    userChannels,
    selectedUserChannelId,
    messages,
    isMessagesInitialLoading,
    hasMessagesNextPage,
    isMessagesFetchingNextPage,
    fetchMessagesNextPage,
    removeWidgetUserChannelId,
    clearSelectedUserChannel,
  } = useChatState() as ChatContextValue;
  const router = useRouter();

  // Get the other user's channel to determine read status
  const isInsideInbox = router.pathname === '/inbox';

  // Derive selected user channel
  const selectedUserChannel = userChannels.find((userChannel) => {
    return userChannel.id === selectedUserChannelId;
  });
  // Get both users in the channel
  const users = (selectedUserChannel?.channel?.user_channels?.items || [])
    .map((userChannel) => {
      return userChannel?.user;
    })
    .filter((user) => !!user) as User[];
  // Get receiver user channel to determine read status
  const receiverUserChannel = (selectedUserChannel?.channel?.user_channels?.items || []).find(
    (userChannel) => {
      return userChannel?.id !== selectedUserChannelId;
    },
  );

  /* UIs */

  let temp = 0;
  const shouldShowAvatar = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    if (currentMessage.sender_id !== previousMessage.sender_id) {
      temp = 0;
      return true;
    }
    temp = temp + 1;
    if (temp === 15) {
      temp = 0;
      return true;
    }
    return false;
  };

  const handleOnScroll = () => {
    // If there is no selected user channel, no more messages to fetch, or
    // it is loading, do nothing
    if (
      !selectedUserChannelId ||
      !hasMessagesNextPage ||
      isMessagesInitialLoading ||
      isMessagesFetchingNextPage
    )
      return;
    // If no container div, do nothing
    if (!messagesContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const isScrollable = scrollHeight > clientHeight;
    const isNearTop = scrollTop < TOP_TRIGGER_FETCHING_THRESHOLD;

    // If the container is not scrollable, or is near top, fetch
    if (!isScrollable || isNearTop) {
      // Store the current scroll height before fetching more messages, so that
      // we can offset the scrollbar after the next page is added to the top
      prevScrollHeight.current = messagesContainerRef.current.scrollHeight;
      fetchingTypeRef.current = MessageFetchingType.NEXT;
      fetchMessagesNextPage();
      return;
    }
  };

  // Whenever selectedUserChannelId changes, reset fetchingTypeRef.current to INITIAL
  useEffect(() => {
    if (selectedUserChannelId) {
      fetchingTypeRef.current = MessageFetchingType.INITIAL;
    }
  }, [selectedUserChannelId]);

  // This effect adjust the scrollbar after messages are fetched, sent, or received
  useEffect(() => {
    if (messages.length === 0) return;

    // If it is the first fetch, adjust the scrollbar to the bottom after the first fetch completes
    if (fetchingTypeRef.current === MessageFetchingType.INITIAL && bottomAnchorRef.current) {
      bottomAnchorRef.current.scrollIntoView();
      fetchingTypeRef.current = undefined;
      return;
    }

    // If it is the next fetch, adjust the scrollbar using scrollTop after new messages are added to the top
    if (fetchingTypeRef.current === MessageFetchingType.NEXT && messagesContainerRef.current) {
      // After new messages are added to the top, offset the scroll position
      // to prevent the scrollbar from sticking to the top and fetching more messages nonstop
      const currentScrollHeight = messagesContainerRef.current.scrollHeight;
      const scrollOffset = currentScrollHeight - prevScrollHeight.current;
      // Reduce the offset a bit to improve UI
      messagesContainerRef.current.scrollTop += scrollOffset - OFFSET_DECREASE_CONSTANT;
      fetchingTypeRef.current = undefined;
      return;
    }

    // If messages are updated, but fetchingTypeRef.current is not set, it means
    // that a new message is received (either by the other user or by the user).
    // Adjust the scrollbar downward only if the scrollbar was previously at the bottom.
    if (!fetchingTypeRef.current && bottomAnchorRef.current && messagesContainerRef.current) {
      // Get the last message
      const lastMessage = messages[messages.length - 1];

      // If the lastMessage is sent by user, just scroll to the bottom
      if (lastMessage.sender_id === selectedUserChannel?.user_id) {
        bottomAnchorRef.current.scrollIntoView();
        return;
      }

      // If the lastMessage is sent by the other user, check if the scrollbar was at the bottom
      // before the new message was added. If it was, adjust the scrollbar to the bottom
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const lastMessageDiv = document.getElementById(lastMessage?.id);
      if (!lastMessageDiv) return;

      // Check if the scrollbar was at the bottom before the new message was added by using
      // the lastMessageDiv's clientHeight
      if (scrollTop + clientHeight + lastMessageDiv.clientHeight >= scrollHeight - 1) {
        bottomAnchorRef.current.scrollIntoView();
        return;
      }
    }
  }, [messages, selectedUserChannel?.user_id]);

  if (!selectedUserChannelId) {
    return (
      <div className="hidden md:flex flex-grow items-center justify-center">
        <p className="text-xl font-semibold">Chọn một đoạn chat để bắt đầu trò chuyện</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-clip h-full">
      {/* Chat header */}
      <div className="flex flex-col shadow-sm md:shadow-none bg-white z-10">
        <div className={`flex ${isChatWidget ? 'px-2 py-2' : 'px-2 md:px-4 py-2 md:py-3'}`}>
          <div className="grow">
            <InboxAvatarView isChatWidget={isChatWidget} />
          </div>

          {!isInsideInbox && (
            <div className="flex space-x-1">
              <button
                className="flex justify-center items-center"
                onClick={() => {
                  clearSelectedUserChannel();
                }}
              >
                <Image src="/ic_minimization.svg" priority width={24} height={24} alt="Close" />
              </button>
              <button
                className="flex justify-center items-center"
                onClick={() => {
                  removeWidgetUserChannelId(selectedUserChannelId);
                }}
              >
                <Image src="/ic-close.svg" priority width={24} height={24} alt="Close" />
              </button>
            </div>
          )}
        </div>
        <DrDivider />
      </div>

      {/* Chat messages and input */}
      <div className="flex flex-col h-full overflow-y-auto">
        {/* Message list view */}
        <div className="flex flex-col flex-grow overflow-y-auto h-full">
          {(isMessagesInitialLoading || isMessagesFetchingNextPage) && (
            <div className="flex w-full justify-center pt-2">
              <CircularProgress
                color="primary"
                size={'1.5rem'}
                sx={{
                  color: '#1976D2',
                }}
              />
            </div>
          )}
          {!isMessagesInitialLoading && messages.length === 0 && (
            <div className="invisible md:visible flex h-full w-full items-center justify-center">
              <p className="text-content-t100 text-xl font-semibold">Bắt đầu trò chuyện</p>
            </div>
          )}
          {!isMessagesInitialLoading && messages.length > 0 && (
            <div
              className={`flex flex-col overflow-y-auto w-full ${
                isChatWidget ? 'px-2' : 'px-4'
              } pt-4`}
              // Add debounce to prevent too many calls
              onScroll={debounce(handleOnScroll, 200)}
              ref={messagesContainerRef}
            >
              {messages.map((message, index) => (
                <div key={index} id={message.id}>
                  <MessageItemView
                    isFirstMessage={index === 0}
                    users={users}
                    receiverUserChannel={receiverUserChannel}
                    message={message}
                    shouldShowAvatar={shouldShowAvatar(index)}
                    isMyMessage={message.sender_id !== receiverUserChannel?.user_id}
                    isChatWidget={isChatWidget}
                  />
                </div>
              ))}
              {/*
                Use overflowAnchor: auto to keep the scroll position at the bottom when new messages are added
                Ref: https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
                */}
              <div className="h-px shrink-0" ref={bottomAnchorRef} />
            </div>
          )}
        </div>

        {/* Input */}
        <MessageInputView isChatWidget={isChatWidget} />
      </div>
    </div>
  );
}
