import { LG_SCREEN_IN_PX, MD_SCREEN_IN_PX } from '@src/utils/constants';
import { ScreenSizeType } from '@src/utils/enums';
import { useCallback, useEffect, useState } from 'react';

/**
 * Follow this post for implementation: https://stackoverflow.com/a/59185109
 */
export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    let isMobile = false;
    let screenSizeType = ScreenSizeType.DESKTOP;

    if (!width) return {};
    if (width < MD_SCREEN_IN_PX) {
      screenSizeType = ScreenSizeType.MOBILE;
      isMobile = true;
    } else if (width < LG_SCREEN_IN_PX) {
      screenSizeType = ScreenSizeType.TABLET;
      isMobile = false;
    } else {
      screenSizeType = ScreenSizeType.DESKTOP;
      isMobile = false;
    }

    return {
      width,
      height,
      isMobile,
      screenSizeType,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow, getWindowDimensions]);

  return windowDimensions;
}
