import { customImageLoader } from '@src/utils/media';
import Image from 'next/image';
import Link from 'next/link';

export type MainAvatarInputType = {
  sizeType: '2xs' | 'xs' | 'xs-medium' | 'xs-tall' | 'sm' | 'md' | 'lg';
  imageSrc: string | null | undefined;
  linkUrl: string;
  displayName: string;
  onClick?: () => void;
  canOpenProfile?: boolean;
  isOpenNewTab?: boolean;
};

export default function MainAvatar({
  sizeType,
  imageSrc,
  linkUrl,
  displayName,
  onClick,
  canOpenProfile = true,
  isOpenNewTab = false,
}: MainAvatarInputType) {
  const getCustomCss = () => {
    switch (sizeType) {
      case '2xs':
        return `flex h-6 w-6 relative rounded-full hover:brightness-95
          items-center justify-center bg-secondary-s3`;
      case 'xs':
        return `flex h-8 w-8 relative rounded-full hover:brightness-95
          items-center justify-center bg-secondary-s3`;
      case 'xs-medium':
        return `flex h-9 w-9 relative rounded-full hover:brightness-95
              items-center justify-center bg-secondary-s3`;
      case 'xs-tall':
        return `flex h-10 w-10 relative rounded-full hover:brightness-95
              items-center justify-center bg-secondary-s3`;
      case 'sm':
        return `flex h-12 w-12 relative rounded-full hover:brightness-95
          items-center justify-center bg-secondary-s3 text-l`;
      case 'md':
        return `flex h-14 w-14 relative rounded-full hover:brightness-95
          items-center justify-center bg-secondary-s3`;
      case 'lg':
        return `flex h-16 w-16 relative rounded-full hover:brightness-95
          items-center justify-center bg-secondary-s3`;
    }
  };

  const getSize = () => {
    switch (sizeType) {
      case '2xs':
        return 4 * 6;
      case 'xs':
        return 4 * 8;
      case 'xs-medium':
        return 4 * 9;
      case 'xs-tall':
        return 4 * 10;
      case 'sm':
        return 4 * 12;
      case 'md':
        return 4 * 14;
      case 'lg':
        return 4 * 24;
      default:
        return 4 * 16;
    }
  };

  const size = getSize();

  if (imageSrc && imageSrc !== '') {
    return (
      <div className="shrink-0">
        {canOpenProfile && (
          <Link href={linkUrl} passHref prefetch={false}>
            <a
              href="replace"
              target={isOpenNewTab === true ? '_blank' : undefined}
              rel={isOpenNewTab === true ? 'noopener noreferrer' : undefined}
            >
              <div className={getCustomCss()}>
                <Image
                  alt="Profile photo"
                  loader={customImageLoader}
                  className="rounded-full"
                  width={size}
                  height={size}
                  objectFit="cover"
                  role="button"
                  src={
                    imageSrc || 'https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg'
                  }
                />
              </div>
            </a>
          </Link>
        )}

        {!canOpenProfile && (
          <div className={getCustomCss()} onClick={onClick}>
            <Image
              alt="Profile photo"
              loader={customImageLoader}
              className="rounded-full"
              width={size}
              height={size}
              loading="lazy"
              objectFit="cover"
              role="button"
              src={imageSrc || 'https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg'}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="shrink-0">
      {canOpenProfile && (
        <Link href={linkUrl} passHref prefetch={false}>
          <a
            href="replace"
            target={isOpenNewTab === true ? '_blank' : undefined}
            rel={isOpenNewTab === true ? 'noopener noreferrer' : undefined}
          >
            <div className={getCustomCss()}>{displayName.charAt(0).toUpperCase()}</div>
          </a>
        </Link>
      )}
      {!canOpenProfile && (
        <div className={getCustomCss()} onClick={onClick}>
          {displayName.charAt(0).toUpperCase()}
        </div>
      )}
    </div>
  );
}
