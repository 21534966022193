import MessagesView from '@components/inbox/MessagesView';
import MainAvatar from '@components/profile/MainAvatar';
import { useAuthState } from '@providers/AuthProvider';
import { User, UserChannel } from '@src/API';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import dayjs from 'dayjs';
import Image from 'next/image';
import { useRouter } from 'next/router';
import 'photoswipe/dist/photoswipe.css';

function InboxWidgetItemView({ userChannel }: { userChannel: UserChannel }) {
  const { selectUserChannelByUserChannelId, removeWidgetUserChannelId } =
    useChatState() as ChatContextValue;
  const { user } = useAuthState();

  // Get the most recent message
  const userChannelMessages = userChannel.channel?.messages?.items ?? [];
  const userChannelSortedMessages = userChannelMessages.sort((a, b) => {
    if (!a?.publication_date_time || !b?.publication_date_time) return 0;
    const aDateTime = new Date(a.publication_date_time);
    const bDateTime = new Date(b.publication_date_time);
    return bDateTime.getTime() - aDateTime.getTime();
  });
  const mostRecentMessage = userChannelSortedMessages[0] || null;
  // Check if user has read the most recent message
  const isUnread = () => {
    if (mostRecentMessage?.sender_id === user?.id) return false;

    const lastTimeReadMessage = dayjs(userChannel.update_date_time);
    const lastMessageDateTime = dayjs(mostRecentMessage?.publication_date_time);

    if (!lastMessageDateTime.isValid() || !lastTimeReadMessage.isValid()) return false;

    return lastMessageDateTime.isAfter(lastTimeReadMessage);
  };
  const hasNewMessage = isUnread();

  // Get the receiver
  const userChannelsOfChannel = userChannel.channel?.user_channels?.items || [];
  const allUsers = userChannelsOfChannel.map((item) => item?.user) as User[];
  const receiver = allUsers.filter((item) => item?.id !== user?.id)[0];

  const handleSelectedUserChannel = async () => {
    selectUserChannelByUserChannelId(userChannel.id);
  };

  /* Views */

  return (
    <div className="group relative rounded-full" key={userChannel?.id || ''}>
      <button onClick={handleSelectedUserChannel}>
        <MainAvatar
          sizeType="sm"
          imageSrc={receiver?.profile_picture_url || ''}
          linkUrl={`/users/${receiver?.username || ''}`}
          displayName={receiver?.display_name || 'Hoạ sĩ'}
          canOpenProfile={false}
          isOpenNewTab={false}
        />
      </button>

      {hasNewMessage && (
        <div className="absolute right-0 top-0 text-m font-semibold bg-red-500 w-2 h-2 rounded-full" />
      )}

      <button
        className="absolute hidden group-hover:flex justify-center items-center right-0 top-0
        bg-white rounded-full shadow-lg p-0.5"
        onClick={() => {
          removeWidgetUserChannelId(userChannel.id);
        }}
      >
        <Image src="/ic-close.svg" priority width={12} height={12} alt="Close" />
      </button>
    </div>
  );
}

export default function InboxWidget() {
  const router = useRouter();

  const { userChannels, selectedUserChannelId, widgetUserChannelIds } =
    useChatState() as ChatContextValue;
  // Derive widgetUserChannels from widgetUserChannelIds
  const widgetUserChannels = userChannels.filter((userChannel) => {
    return widgetUserChannelIds.includes(userChannel.id);
  });

  /* Views */
  if (router.pathname === '/inbox' || widgetUserChannelIds.length === 0) return null;

  return (
    <div className="hidden md:block fixed h-fit w-fit z-50 right-0 bottom-0 pr-4">
      <div className="flex justify-end items-end space-x-3">
        {/* List Chat Widget */}
        {selectedUserChannelId && (
          <div
            className="bottom-0 flex flex-col h-114 w-84 shadow
          rounded-t-xl overflow-hidden border border-greyscale-g2 bg-white"
          >
            <MessagesView isChatWidget />
          </div>
        )}

        {/* User Channel Right Menu */}
        <div className="pb-16 h-fit">
          <div className="flex flex-col space-y-2 items-center">
            {widgetUserChannels.map((userChannel) => (
              <div key={userChannel.id}>
                <InboxWidgetItemView userChannel={userChannel} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
